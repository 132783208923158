<template>
  <div class="meta-box">
    <div class="title-container">
      <h3 class="title">
        {{
          optParticipateStatus === 'alreadyOptIn'
            ? $t('promotion.cryptoCashback.deactive.title')
            : $t('promotion.cryptoCashback.active.title')
        }}
      </h3>
    </div>

    <!-- action btn -->
    <el-row :gutter="20" class="sub-box1">
      <el-col :xs="24">
        <div class="sub-title" :class="{ inactive: optParticipateStatus === 'alreadyOptIn' }">
          {{
            optParticipateStatus === 'alreadyOptIn'
              ? $t('promotion.cryptoCashback.deactive.desc')
              : $t('promotion.cryptoCashback.active.desc')
          }}
        </div>
      </el-col>
      <el-col :xs="24" class="button-box opt-button-box">
        <el-button
          v-if="optParticipateStatus === 'alreadyOptIn'"
          class="el-button btn-blue long-button"
          data-testid="opt"
          @click="onSignOut"
        >
          {{ $t('promotion.cryptoCashback.button.deactive') }}
        </el-button>
        <el-button
          v-else
          class="el-button btn-blue long-button"
          data-testid="opt"
          @click="onActivateCampaign"
          :disabled="optParticipateStatus === 'alreadyOptOut'"
        >
          {{ $t('promotion.cryptoCashback.button.active') }}
        </el-button>
      </el-col>
    </el-row>

    <!-- description -->
    <el-row :gutter="20" class="sub-box2">
      <el-col :xs="24">
        <!-- how to claim -->
        <Description :title="claimTitle" :descriptions="claimDescriptions" />
      </el-col>
      <el-col :xs="24">
        <!-- eligibility -->
        <Description :title="eligibilityTitle" useSlotForLiTag>
          <li
            v-for="(item, index) in eligibilityDescriptions"
            :key="index"
            v-html="getEligibilityDescContext(item, index)"
          />
        </Description>
      </el-col>
      <el-col :xs="24" class="button-box">
        <el-button
          class="el-button btn-blue long-button"
          data-testid="fund"
          @click="goToFund"
          :disabled="optParticipateStatus === 'alreadyOptOut' || !optIn"
        >
          {{ $t('promotion.cryptoCashback.button.fund') }}
        </el-button>
      </el-col>
      <el-col :xs="24" class="agreeTnc-box">
        <i18n path="promotion.cryptoCashback.tnc">
          <template v-slot:link>
            <a :href="tncLink" target="_blank" data-testid="tnc">
              {{ $t('promotion.cryptoCashback.link') }}
            </a>
          </template>
        </i18n>
      </el-col>
    </el-row>

    <!-- cashback table -->
    <div class="cashback-container">
      <CashbackTable />
    </div>

    <!-- dialog -->
    <AccountDialog
      :dialogVisible.sync="showAccountDialog"
      :campaignID="campaignID"
      :accountOptions="accountOptions"
      @sendAccountMt4Dto="closeAndOpenTncDialog"
      @close="onCloseAccountDialog"
    />
    <AgreeTnc
      :agreeTncVisible.sync="showAgreeTnc"
      headerText="promotion.cryptoCashback.dialog.tnc"
      :accountData="accountData"
      @activateSuccess="onActivateSuccess"
    />
    <CallbackDialog
      :show.sync="showResultDialog"
      :callbackType="optStatus"
      @optout="optSignOut"
      @closCbDialog="closCbDialog"
    />
  </div>
</template>

<script>
import { apiGetRedeemableAccounts, apiOptOutWithAccount } from '@/resource';
import { isSuccess } from '@/util/restApi';
import blackList from '@/mixins/blackList';
import Description from './Desciption.vue';
import CashbackTable from './CashbackTable.vue';
import CallbackDialog from '@/components/promotion/cryptoCashback/CallbackDialog.vue';
import AccountDialog from './AccountDialog.vue';
import AgreeTnc from './AgreeTnc.vue';

export default {
  name: 'FundAccount',
  components: {
    CallbackDialog,
    Description,
    AccountDialog,
    CashbackTable,
    AgreeTnc
  },
  props: {
    optIn: {
      type: Boolean,
      default: true
    },
    participantStatus: {
      type: String,
      default: 'OO'
    },
    campaignID: {
      type: Number,
      default: 31
    }
  },
  mixins: [blackList],
  data() {
    return {
      showAccountDialog: false,
      showAgreeTnc: false,
      showResultDialog: false,
      claimTitle: this.$t('promotion.cryptoCashback.claimTitle'),
      claimDescriptions: this.$t('promotion.cryptoCashback.claimDescriptions'),
      eligibilityTitle: this.$t('promotion.cryptoCashback.eligibilityTitle'),
      eligibilityDescriptions: this.$t('promotion.cryptoCashback.eligibleDescriptions'),
      redeemableAccounts: [],
      optStatus: null, // success, failNotOptout, failOptout
      accountData: null
    };
  },
  computed: {
    accountOptions() {
      return this.redeemableAccounts.map(({ mt4Account, currency, datasourceId, accountDisplayType }) => {
        let accountType = this.$config.accountTypeMaps[Number(accountDisplayType)] || null;
        let label = accountType ? `${mt4Account} - ${accountType} - ${currency}` : `${mt4Account} - ${currency}`;
        return {
          label,
          value: {
            mt4Account,
            currency,
            datasourceId
          }
        };
      });
    },
    queryData() {
      return {
        campaignId: this.campaignID,
        accountMt4Dto: { ...this.accountData.value }
      };
    },
    tncLink() {
      let tncVersion = this.regulator === 'svg' ? '_Net' : '';
      return `https://${this.GLOBAL_REFERRAL_SITE}/pdf/0528/Cryptocurrency_Cashback_Terms_and_Conditions${tncVersion}.pdf?v=1613980771`;
    },
    optParticipateStatus() {
      // availableForOptIn, alreadyOptIn, alreadyOptOut
      if (!this.optIn) {
        return 'availableForOptIn';
      } else {
        switch (this.participantStatus) {
          case 'OO':
          case 'EP':
          case 'X':
            return 'alreadyOptOut';
          default:
            return 'alreadyOptIn';
        }
      }
    },
    lang() {
      return this.$store.state.common.lang;
    }
  },
  methods: {
    async refreshEligibleCampaigns() {
      await this.$store.dispatch('promotion/actionGetEligibleCampaigns');
    },
    onCloseAccountDialog() {
      this.showAccountDialog = false;
    },
    goToFund() {
      this.getDepositBlacklist();
    },
    showError() {
      this.$message({
        message: this.$t('common.field.failed'),
        type: 'error',
        duration: 5000
      });
    },
    async onActivateCampaign() {
      try {
        const { data: cbData } = await apiGetRedeemableAccounts(this.campaignID);
        if (isSuccess(cbData)) {
          this.redeemableAccounts = cbData.data;
          this.showAccountDialog = true;
        } else {
          this.closCbDialog();
        }
      } catch (e) {
        this.closeAllDialog();
        return this.showError();
      }
    },
    closeAndOpenTncDialog(accountMt4Dto) {
      this.accountData = { ...accountMt4Dto, campaignID: this.campaignID };
      this.showAccountDialog = false;
      setTimeout(() => {
        this.showAgreeTnc = true;
      }, 100);
    },
    async onActivateSuccess() {
      try {
        await this.refreshEligibleCampaigns();
        this.optStatus = 'success';
        this.showResultDialog = true;
        this.$emit('activeOptIn');
      } catch (error) {
        this.closeAllDialog();
        return this.showError();
      }
    },
    onSignOut() {
      this.optStatus = 'failNotOptout';
      this.showResultDialog = true;
    },
    async optSignOut() {
      try {
        const q = {
          campaignId: this.campaignID,
          accountMt4Dto: null
        };

        const { data: cbData } = await apiOptOutWithAccount(q);
        if (isSuccess(cbData)) {
          // code === 0 means opt-out success
          await this.refreshEligibleCampaigns();
          this.optStatus = 'failOptout';
          this.$emit('deactivateAndQueryData', this.optStatus);
          this.showResultDialog = true;
        } else {
          this.closCbDialog();
          return this.showError();
        }
      } catch (e) {
        this.closeAllDialog();
        return this.showError();
      }
    },
    closCbDialog() {
      this.showResultDialog = false;
      setTimeout(() => {
        this.optStatus = null;
      }, 300);
    },
    closeAllDialog() {
      this.showAccountDialog = false;
      this.showAgreeTnc = false;
      this.closCbDialog();
    },
    getEligibilityDescContext(item, index) {
      if (index === 3) {
        switch (this.lang) {
          case 'en_US':
            return `Cashback is based on volume traded on all cryptocurrency products <span style="font-weight: bold;">EXCEPT</span> the following: ALGUSD, MTCUSD, XRPUSD, BCHUSD, XLMUSD, EOSUSD, BTCBCH, BTCETH, BTCLTC, BTCXAU, ETHBCH, ETHLTC, ETHXAU.`;
          case 'fr_FR':
            return `le cashback est basé sur le volume tradé sur tous les produits de crypto-monnaies <span style="font-weight: bold;">à l'exception</span> des suivants : ALGUSD, MTCUSD, XRPUSD, BCHUSD, XLMUSD, EOSUSD, BTCBCH, BTCETH, BTCLTC, BTCXAU, ETHBCH, ETHLTC, ETHXAU.`;
          default:
            break;
        }
      }
      return item;
    }
  }
};
</script>
